import z from 'zod'

const dfFields = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
] as const;

export const DataCacheSchema: {
        [key: string]: z.ZodEnum<[string, ...string[]]>
    } = {
    'dispute': z.enum([...dfFields]),
    'system-run': z.enum([...dfFields]),
    'webhook': z.enum([...dfFields]),
}