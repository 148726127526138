import { CrmConfigSchema } from "./ConfigSchema/CrmConfigSchema";
import { PpConfigSchema } from "./ConfigSchema/PpConfigSchema";
import { StripeBalanceImport } from "./Reports/StripeBalanceImport";

export const PluginsMap: any = {

    'reports.stripe-dispute.upload-form': StripeBalanceImport,

    config: {
        paypal: PpConfigSchema,
        crm: CrmConfigSchema,
    }
}